.bg-primary {
    background-color: $primary !important;
}
  
.bg-dark {
    background-color: $gray-900 !important;
}

.bg-light {
    background-color: #f7faff;
}
  
.text-faded {
    color: fade-out($white, .3);
}