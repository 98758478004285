.news {
  box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  &__body {
    padding: 1.5rem 1rem;
  }

  &__thumbnail{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: .3s ease-in-out;

    &--wrapper{
      position: relative;
      width: 100%;
      height: 300px;
      overflow: hidden;
    }
  }

  &:hover {
    .news__thumbnail{
      transform: scale(1.2) rotate(5deg)
    }
  }
}
