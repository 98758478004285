.text-primary {
  color: $primary !important;
}

ul.page-numbers{
  padding-left: 0;
  list-style: none;
  margin-top: 1.5rem;

  li {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 2px;
      line-height: 30px;
      
      a {
        color: inherit!important;
      }
      
      & > span.current{
        display: block;
        color: #fff;
        border-radius: 2px;
        background: $primary;
      }
  }
}
