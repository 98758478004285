a {
    color: $primary;
    text-decoration: none;
    @include transition-all;

    &:hover {
      color: darken($primary, .1);
      text-decoration: none;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include sans-serif-font;
  }