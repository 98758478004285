html {
  scroll-behavior: smooth;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  @include serif-font;
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}
