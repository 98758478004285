section {
    padding: 3rem 0;

    @include media-breakpoint-up(md){
        padding: 5rem 0;
    }
}
  
.section-heading {
    font-weight: 700;
    margin-top: 0;
}

.section-half {
    padding: 3rem 0;
}

.section-image {
    background: url('../img/content/subsite.jpg');
    background-size: cover;
}

.page-content {
    img{
        max-width: 100%;
        height: auto;
    }
}