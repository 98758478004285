.navbar {
  padding: 0 1rem;
  background: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

  @include transition-all;

  .navbar-brand {
    img {
      max-width: 90px;
    } 
  }

  .navbar-nav {
    > li.nav-item {
      > a.nav-link,
      > a.nav-link:focus {
        font-size: 0.9rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-900;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav > li.nav-item > a.nav-link {
      padding: 0.5rem 1rem;
    }
  }
}
