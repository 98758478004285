.btn {
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    border-radius: 300px;
    @include sans-serif-font;
  }
    
  .btn-xl {
    padding: .8rem 1.6rem;
  }
  
  .btn-primary {
    background-color: $primary;
    border-color: $primary;
  
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: darken($primary, 5%) !important;
    }
    &:active,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 73, 240, 0.5) !important;
    }
  }