.footer {
    a {
        color: #fff;
    }
    &__logo {
        max-height: 150px;
      }
    &__copyrights {
        font-size: 12px;
    }
}