.slidex {
  width: 100%;
  height: 400px;
  margin-top: 70px;

  @include media-breakpoint-up(md){
    height: 700px;
  }

  &__img {
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #efefef;
    background-blend-mode: multiply;
    
    @include media-breakpoint-up(md){
      height: 700px;
    }
  }
}
  